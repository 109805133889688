import React from 'react'


const GVKeyDiclaimer = (props) => {
    return (
      <div>
      <h1>Attribution and Disclaimer for GVKey Data</h1>
      <p><a href="https://www.spglobal.com/en/" target="_blank" rel="noopener noreferrer">S&P Global Market Intelligence.</a> None of S&P, its affiliates or their suppliers guarantee the accuracy, adequacy, completeness or availability of any information and is not responsible for any errors or omissions, regardless of the cause or for the results obtained from the use of such information. In no event shall S&P, its affiliates or any of their suppliers be liable for any damages, costs, expenses, legal fees, or losses (including lost income or lost profit and opportunity costs) in connection with any use of S&P information.</p>
      </div>
    )
}

export default GVKeyDiclaimer